/** @format */

.pell {
	border: 1px solid hsla(0, 0%, 4%, 0.1);
}
.pell,
.pell-content {
	box-sizing: border-box;
}
.pell-content {
	min-height: 150px;
	outline: 0;
	overflow-y: auto;
	padding: 10px;
}
.pell-actionbar {
	background-color: #fff;
	border-bottom: 1px solid hsla(0, 0%, 4%, 0.1);
}
.pell-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	height: 30px;
	outline: 0;
	width: 30px;
	vertical-align: bottom;
}
.pell-button-selected {
	background-color: #f0f0f0;
}
