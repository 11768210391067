/** @format */

.navbar-minimalize {
	position: absolute;
	top: 14px;
	right: 6px;
	color: $navy;
	transition: color 1s;

	&:hover {
		color: $light-orange;
	}
}

.nav-header {
	padding: 0;
}

.nav > li > a iconify-icon {
	font-size: 1rem;
	margin-right: 6px;
	vertical-align: text-bottom;
}

/*.nav-link {
  padding: 0!important;
}*/

.nav-image {
	width: 24px;
	height: 24px;
	margin: 0 5px;

	&:hover {
		svg {
			path {
				fill: $light-orange;
				transition: all 1s;
			}
		}
	}

	svg {
		width: 100%;
		height: 100%;

		path {
			transition: all 1s;
		}
	}
}

.nav-image--search {
	width: 18px;
}

body {
	.navbar-static-side {
		position: fixed;

		.nav > li > a {
			padding: 10px 20px 10px 25px;
		}
	}

	.profile-element {
		text-align: left;
		font-size: 16px;
		padding: 12px 20px;
		font-weight: 600;
		color: white;

		img {
			width: 24px;
			height: 24px;
		}
	}

	.logo-element {
		display: none;
	}

	#page-wrapper {
		transition: all 1s;
		margin-left: 70px;
	}

	.navbar-fixed-top {
		padding: 4px 1rem;
		transition-duration: unset;
		width: calc(100% - 70px);
		left: 70px;
		z-index: 10;
	}

	&.mini-navbar {
		.nav-header {
			background-color: #2f4050;
		}

		.profile-element {
			display: none;
		}

		.logo-element {
			font-size: 16px;
			padding: 12px 14px;
			display: block;

			img {
				width: 24px;
				height: 24px;
			}
		}

		.navbar-default .nav > li > a {
			font-size: unset;
		}

		#page-wrapper {
			transition: all 1s;
			margin-left: 70px;
		}

		.navbar-fixed-top {
			width: calc(100% - 70px);
			left: 70px;
		}
	}
}

@media (max-width: 576px) {
	.dropdown-menu.menu-center {
		font-size: larger;
	}

	body {
		.wrapper {
			padding: 0;
		}

		#page-wrapper {
			margin-left: 0;
		}

		.menu_active {
			left: 0;
			z-index: 2000;
			opacity: 0.95;
		}

		.navbar-fixed-top {
			padding: 4px 1rem;
			transition-duration: unset;
			width: 100%;
			left: 0;
			z-index: 10;

			.hamburger-menu {
				display: block;
				position: absolute;
				right: 23px;
				top: 30px;
				background-color: white;
				transform: translateY(-50%);
				width: 40px;
				height: 33px;
				z-index: 20;

				&-active {
					span {
						&:nth-child(1) {
							transform: translateY(7px) rotate(-45deg);
						}

						&:nth-child(2) {
							display: none;
						}

						&:nth-child(3) {
							transform: translateY(-4px) rotate(45deg);
						}
					}
				}

				.hamburger-menu-line {
					display: block;
					width: 100%;
					height: 4px;
					margin-bottom: 7px;
					background-color: #616161;
					transition: all 0.25s ease-in-out;
					border-radius: 3px;
				}
			}
		}
	}
}

.pagination > li > a,
.pagination > li > span {
	padding: 0px 4px;
}
