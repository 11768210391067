/** @format */

@media (min-width: 768px) {
	#page-wrapper {
		position: inherit;
		min-height: 100vh;
	}

	.navbar-static-side {
		z-index: 2001;
		width: $sidebar-width;
		background-color: #2f4050;
	}

	.navbar-top-links .dropdown-messages,
	.navbar-top-links .dropdown-tasks,
	.navbar-top-links .dropdown-alerts {
		margin-left: auto;
	}
}

@media (max-width: 768px) {
	#page-wrapper {
		position: inherit;
		margin: 0 0 0 0;
		min-height: 100vh;
		width: 100%;
	}

	.body-small .navbar-static-side {
		display: block;
		z-index: 2001;
		width: 0;
		overflow: hidden;
	}

	.body-small.mini-navbar .navbar-static-side {
		display: block;
		overflow: visible;
	}

	.lock-word {
		display: none;
	}

	.navbar-form-custom {
		display: none;
	}

	.navbar-header {
		display: inline;
		float: left;
	}

	.sidebar-panel {
		z-index: 2;
		position: relative;
		width: auto;
		min-height: 100% !important;
	}

	.sidebar-content .wrapper {
		padding-right: 0;
		z-index: 1;
	}

	.fixed-sidebar.body-small .navbar-static-side {
		display: none;
		z-index: 2001;
		position: fixed;
		width: $sidebar-width;
	}

	.fixed-sidebar.body-small.mini-navbar .navbar-static-side {
		display: block;
	}

	.ibox-tools {
		float: none;
		text-align: right;
		display: block;
	}

	body:not(.mini-navbar) {
		-webkit-transition: background-color 500ms linear;
		-moz-transition: background-color 500ms linear;
		-o-transition: background-color 500ms linear;
		-ms-transition: background-color 500ms linear;
		transition: background-color 500ms linear;
		background-color: #f3f3f4;
	}
}

@media (max-width: 576px) {
	body,
	.btn {
		font-size: 1rem !important;
	}
	.btn {
		white-space: nowrap;
	}
	svg {
		width: 20px;
		height: 20px;
	}
	.close {
		font-size: 2.2rem;
	}
	.custom-datepicker {
		width: 247px !important;
	}
	body .navbar-static-side {
		font-size: 21px;
	}
	footer {
		z-index: 15;
		position: inherit;
	}
	.footer-margin {
		margin-bottom: 93px !important;
	}
	.navbar-procedure-dropdown {
		min-width: 175px !important;
	}
	.order-of-repayment__popover {
		width: 100% !important;
	}
	.my-popover {
		width: 100% !important;
	}
	textarea.form-control {
		height: 200px;
	}
	#datetimepicker-procedure-hearing_date_and_time {
		width: 100% !important;
		.datetimepicker-input {
			width: calc(100% - 45px) !important;
		}
		.bootstrap-datetimepicker-widget {
			width: 100%;
		}
	}
	.table-mobile {
		display: block;
		width: 100%;
		overflow-x: auto;
	}
	.hidden-mobile {
		display: none !important;
	}
	.flex-grow-slot {
		justify-content: end !important;
	}
	.raf__tooltip {
		width: 250px !important;
		white-space: break-spaces !important;
	}
}
