/** @format */

.draft {
	color: red;
	border-bottom: 1px solid red;
}

.done {
	color: $navy;
	border-bottom: 1px solid $navy;
}

.documents-wrap {
	display: inline;
	width: 100%;
	cursor: pointer;
}

.documents-handle {
	padding-left: 5px;
}

.documents-btn-wrap {
	padding: 7px 0 14px 0;
}
