/** @format */

.raf__with-tooltip {
	position: relative;

	.raf__tooltip {
		display: none;

		p {
			margin-bottom: 0.4rem;

			&:last-child {
				margin-bottom: 0.2rem;
			}
		}
	}

	&:hover,
	&:focus {
		.raf__tooltip {
			position: absolute;
			z-index: 10010;
			top: calc(100% + 0.4rem);
			color: #212529;
			display: block;
			font-size: 1em;
			font-weight: normal;
			line-height: 1.2em;
			outline: none;
			padding: 0.3rem 0.5rem;
			text-align: left;
			text-shadow: none;
			border-radius: 0.3rem;
			opacity: 1;
			background-color: #fff;
			border: solid 1px #676a6c;
			box-shadow: 0 0 0.3rem #676a6c;
			width: max-content;

			&.raf__tooltip_info {
				background-color: lightcyan;
			}

			&.raf__tooltip_danger {
				background-color: mistyrose;
			}
			/*lightblue
        lightcyan
        lightskyblue
        aquamarine
        paleturquoise
        powderblue
        &:after {
        position: absolute;
        content: '';
        z-index: 10011;
        left: 1rem;
        top: calc(-.4rem - 2px);
        opacity: 1;
        background-color: transparent;
        border-top: 0;
        border-left: .4rem solid transparent;
        border-right: .4rem solid transparent;
        border-bottom: .5rem solid #676a6c;
      }*/
		}
	}
}

.tooltip-wrapper {
	vertical-align: middle;
}
