/** @format */

.tox .tox-collection--grid .tox-collection__group {
	display: block !important;
}

.editing__page .tox {
	.tox-editor-header {
		position: fixed;
		margin-top: -5px;
	}
}
