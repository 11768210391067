/** @format */

.captcha-dependent.disabled {
	pointer-events: unset;
	cursor: not-allowed;

	> a {
		cursor: not-allowed;
		opacity: 0.5;
	}
}
