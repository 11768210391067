/** @format */

// Bootstrap
@import 'bootstrap';

// Variables, Mixins
@import 'base/variables';
@import 'base/typography';
@import 'base/navigation';
@import 'base/top_navigation';
@import 'base/buttons';
@import 'base/badges_labels';
@import 'base/elements';
@import 'base/sidebar';
@import 'base/base';
@import 'base/pages';
@import 'base/chat';
@import 'base/metismenu';
@import 'base/spinners';

// RTL Support
@import 'base/rtl';

// Media query style
@import 'base/media';

// Custom style
@import 'custom/style';

// Clear layout on print mode
@media print {
	body {
		overflow: visible !important;
	}
	#page-wrapper {
		margin: 0;
	}
	nav.navbar-static-side {
		display: none;
	}
}

// ADVANCE
@import 'advance/font-awesome.min'; // нужен для датапикера
@import 'advance/animate';
@import 'advance/tempusdominus-bootstrap-4.min';
@import 'advance/suggestions.min';
@import 'advance/pell.min';

@import 'select2/src/scss/core';
