/**
 * This would all go into the global.css file
 *
 * @format
 */

[data-tooltip] {
	position: relative;
	display: block;
}

[data-tooltip]:before,
[data-tooltip]:after {
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	z-index: 1;
}

[data-tooltip]:before {
	position: absolute;
	bottom: 100%;
	margin-left: -7px;
	margin-bottom: 3px;
	padding: 2px;
	width: 300%;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background-color: #000;
	background-color: hsla(0, 0%, 20%, 0.9);
	color: #fff;
	content: attr(data-tooltip);
	text-align: center;
	font-size: 14px;
	line-height: 1.2;
	transition: 0.2s ease-out;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
	visibility: visible;
	opacity: 1;
}
[data-tooltip='false']:hover:before,
[data-tooltip='false']:hover:after {
	visibility: hidden;
	opacity: 0;
}
