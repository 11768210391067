/** @format */

.select2 {
	height: unset !important;
	/*height: 100% !important;*/
}

.select2-results__options {
	border-radius: 4px;
}

.unsuitable {
	font-style: italic;
	color: #9ea2a6;
}

.select2-container .select2-search--inline .select2-search__field {
	margin-top: 0.5em !important;

	&::placeholder {
		color: #c0c0c0 !important;
		font-style: italic;
		font-size: 1.1em;
		padding-left: 0.3em !important;
	}
}

.select2-container .select2-selection--multiple .select2-search__field {
	display: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
	border: none !important;

	/*line-height: unset !important;

  .select2-selection__choice {
    line-height: 1.5 !important;
  }*/
}
.select2-container--default .select2-results__option--selected {
	display: none;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
	padding-left: 7px !important;
	line-height: 25px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	padding: 0 !important;
	float: right !important;
	font-size: 1.1em;
	//line-height: 1.4em;
	color: #ffffff !important;
	margin: 3px !important;
	margin-right: 0 !important;
	border: none !important;
	height: 20px !important;
	width: 20px !important;
	border-radius: 10px !important;
	background-color: #52616f !important;
	position: relative !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
	background-color: #ffffff !important;
	color: #007bff !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
	margin-top: 0.5em !important;
	margin-bottom: 0.2em !important;
	border: none !important;
	outline: none !important;
	color: #676767;
	padding-top: 3px !important;
	padding-bottom: 3px !important;
	padding-right: 7px !important;
	padding-left: 7px !important;
	border-radius: 15px !important;
}

.wrapper-active .select2-container--default .select2-selection--multiple .select2-selection__choice:hover {
	background-color: #007bff;
	color: #ffffff;
}

.wrapper-active .select2-container--default .select2-selection--multiple .select2-selection__choice {
	color: #1f1f1f;
}
.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
	cursor: not-allowed !important;
}

.select2-container--disabled .select2-selection--multiple .select2-selection__choice__display {
	cursor: not-allowed !important;
}

.select2-container--disabled .select2-selection--multiple {
	background-color: #f9f7f7 !important;
	cursor: not-allowed !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
	margin-top: 0.2em !important;
	margin-right: 0.2em !important;
	font-size: 1.5em;
	color: #999;

	&:hover {
		color: #333;
	}
}

.select2-container.select2-container--default .select2-selection--multiple,
.select2-container.select2-container--default.select2-container--focus .select2-selection--multiple {
	border: solid #1ab394 1px;
	/*padding-bottom: .5em;*/

	&.has-success {
		border: solid #1ab394 1px;
	}
}

.select2-container--default .select2-results > .select2-results__options {
	background-color: #fafafa;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #b9daff !important;
	color: inherit !important;
}
