/** @format */

@import 'variables';

body {
	font-size: 0.81rem;
}

/* ------------------------------------------------------- */
.form-field {
	.selectContainer,
	.form-control {
		border-color: #aaaaaa !important;
		z-index: auto !important;
		.listContainer {
			z-index: 10 !important;
		}
	}
	input:focus,
	textarea:focus {
		border-color: #aaaaaa;
	}

	&.field-error {
		.selectContainer,
		.form-control {
			border: 1px solid #ed5565 !important;
		}
		& > div > input,
		& > div > div.input-wrapper > input,
		& > div > textarea {
			border: 1px solid #ed5565 !important;
		}
	}
	&.field-success {
		.selectContainer,
		.form-control {
			border: 1px solid #1ab394 !important;
		}
		& > div > input,
		& > div > div.input-wrapper > input,
		& > div > textarea {
			border: 1px solid #1ab394 !important;
		}
	}
	&.field-warning {
		.selectContainer,
		.form-control {
			border: 1px solid #f8ac59 !important;
		}
		& > div > input,
		& > div > div.input-wrapper > input,
		& > div > textarea {
			border: 1px solid #f8ac59 !important;
		}
	}
	&.field-disabled {
		.selectContainer,
		.form-control {
			border: 1px solid #aaaaaa !important;
			color: #a8a8a8 !important;
			background-color: #f9f7f7 !important;
		}
		& > div > input,
		& > div > div.input-wrapper > input,
		& > div > textarea {
			border: 1px solid #aaaaaa !important;
		}
	}
}
.short {
	input,
	div.form-control {
		// col-4
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
		min-width: 155px;
	}
}
.date-control {
	input,
	div.form-control {
		// col-4
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
		min-width: 155px;
	}
}
.time-control {
	input,
	div.form-control {
		// col-1
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
		min-width: 95px;
	}
}
/* ------------------------------------------------------- */

.sortable {
	cursor: pointer;

	&:not([data-sort-direction]):after {
		content: '\f0dc';
		font-family: FontAwesome;
		font-size: 0.8em;
		color: #337ab7;
		margin-left: 7px;
	}

	&[data-sort-direction='asc']:after {
		content: '\f0de';
		font-family: FontAwesome;
		color: #337ab7;
		margin-left: 7px;
	}

	&[data-sort-direction='desc']:after {
		content: '\f0dd';
		font-family: FontAwesome;
		color: #337ab7;
		margin-left: 7px;
	}
}

.hidden {
	display: none;
}

.navbar-fixed-top {
	background-color: #ffffff !important;
}

.align-nav-logo {
	display: flex !important;
	align-items: center;
	justify-content: center;
	height: 75px;
}

.mini-navbar .navbar-minimalize {
	top: 50%;
	transform: translateY(-50%);
}

.dropdown-item:hover {
	cursor: pointer;
}

attr.required {
	color: #f7a54a;
}

button:disabled {
	background-color: #e9ecef;
	border: 1px solid #ced4da;
}

.inmodal .modal-header {
	padding: 1rem;
}

.modal-body {
	padding: 1.2rem 2rem;
}

.select-wrapper {
	position: relative;
}

.select-placeholder {
	position: absolute;
	top: 6px;
	left: 13px;
	z-index: 1;
	font-size: 14px;
	font-style: italic;
	color: #c0c0c0;
}

.datetimepicker-input {
	width: 105px !important;
	flex: none !important;
}

.datetimepicker {
	position: relative;
	width: 161px; /*247px;*/

	&.widget-left {
		.bootstrap-datetimepicker-widget {
			left: -100px !important;
		}
	}
}

.custom-datepicker {
	position: relative;
	width: 187px;

	.datetimepicker-input {
		width: 145px !important;
	}

	.bootstrap-datetimepicker-widget {
		width: 410px;

		.row {
			margin: 0;

			.col-md-6 {
				flex: unset;
				max-width: unset;
			}

			.datepicker {
				padding-left: 0;
				padding-right: 0;
				width: 237px;
			}

			.timepicker {
				/*padding-left: 15px;*/
				padding-right: 0;
				width: 158px;

				table.table-condensed td {
					height: 48px;
					line-height: 48px;
					width: 48px;
					/*font-size: .8rem !important;*/

					span {
						height: 48px;
						width: 48px;
					}

					&.separator {
						width: 24px;
					}
				}
			}
		}
	}
}

.bootstrap-datetimepicker-widget.dropdown-menu.usetwentyfour {
	/*left: 152px !important;
    &.bottom {
      top: -1px !important
    }
    &.top {
      bottom: -1px !important
    }
  */
	width: 245px;

	th,
	td {
		font-size: 0.87rem !important;
	}
}

.time-only {
	.bootstrap-datetimepicker-widget.dropdown-menu.usetwentyfour {
		width: 72px;
	}

	.datetimepicker-input {
		width: 72px !important;
	}

	.bootstrap-datetimepicker-widget {
		width: 72px;

		&.dropdown-menu {
			min-width: 112px;
		}

		table td {
			height: 24px;
			line-height: 24px;
			width: 24px;

			padding: 0;
		}

		a[data-action] {
			padding: 0;
		}

		span {
			display: inline-block;
			width: 22px;
			height: 22px;
			line-height: 22px;
			margin: 1px;
			cursor: pointer;
			border-radius: 0.25rem;
		}

		&.separator {
			width: 20px;
		}
	}
}

.phantom,
.hide-placeholder {
	display: none;
}

.form-control {
	position: relative;
	z-index: 9;
	background: transparent !important;
	border: 1px solid #aaaaaa;

	&::placeholder {
		font-style: italic;
		color: #c0c0c0;
	}

	&:disabled {
		cursor: not-allowed;
		border: 1px solid #a8a8a8;
		background-color: #f9f7f7 !important;
		color: #a8a8a8;
	}
}

.required-border {
	border: 1px solid #f7a54a !important;
}

.text-disabled {
	color: #a8a8a8;
}

.overlay {
	padding: 15px;
	background-color: #ffffff;
	border: 1px solid #e7eaec;
}

.footer {
	/*position: relative!important;*/
	width: 100%;
}

.flex-box {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.flex {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.pure-flex {
	display: flex;
}

.wrapper {
	padding: 0 15px;
}

.popover {
	top: 0 !important;
}

.logo {
	width: 100%;
	height: auto;
	margin-bottom: 20px;

	img {
		width: 100%;
	}
}

.reg-link {
	display: inline-block;
	padding: 0 6px;
}

.align-vertical {
	display: flex;
	align-items: center;
	height: 100%;
}

.passwordBox {
	padding: 0 !important;
}

.flex-slot {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.flex-shrink-slot {
	flex-shrink: 0;
	width: 42%;
}

.flex-grow-slot {
	flex-grow: 1;
	display: flex;
	justify-content: flex-start;
}

.border-bottom {
	padding-bottom: 5px;
	box-sizing: border-box;
	border-bottom: 1px solid #a8a8a8;
}

.navbar-default .nav > li > a {
	opacity: 0.5;
}

.navbar-default .nav > li.active-icon > a {
	opacity: 1;
}

.navbar-default .nav > li > a:hover,
.navbar-default .nav > li > a:focus {
	background-color: transparent;
	color: #a7b1c2;
	cursor: not-allowed;
}

.navbar-default .nav > li.active-icon > a:hover,
.navbar-default .nav > li.active-icon > a:focus {
	background-color: #293846;
	color: white;
	cursor: pointer;
}

.select2 {
	width: 100% !important;
	height: 35px !important;

	&-dropdown {
		z-index: 3051 !important;
	}
}

.select2-selection {
	height: 100% !important;
	background: transparent !important;
	border: 1px solid #aaaaaa;

	&.select2-selection--single.has-success {
		border: 1px solid #1ab394;
	}

	&.select2-selection--single.has-error {
		border: 1px solid#ed5565;
	}

	.select2-selection__arrow {
		height: 2rem !important;
	}
}

.select2-selection.select2-selection--single {
	background-color: #fff !important;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
	font-size: 14px;
	font-style: italic;
	color: #c0c0c0 !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #676a6c !important;
	font-size: 0.9rem !important;
	line-height: 2rem !important;
}

/*.select2-selection__rendered {
  color: #495057 !important;
  line-height: 2rem !important;
  font-size: 0.9rem;
}*/

.select2.select2-container.select2-container--default.select2-container--disabled .select2-selection__rendered {
	color: #a8a8a8 !important;
	font-size: 1em;
	line-height: 2em !important;
	cursor: not-allowed;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
	background-color: #fafafa !important;
	cursor: not-allowed;
}

.select2-dropdown {
	z-index: 4000;
}

.tooltip-color {
	color: #676a6c;
}

.tooltip {
	margin-top: -25px !important;
	background-color: #000000 !important;
	opacity: 0.5 !important;
	color: #fff !important;
	padding: 5px !important;
}

.checkbox-left {
	padding-left: 10px;
}

.checkbox-top {
	margin-top: -25px;
}

.dropdown-menu--custom {
	min-width: 109px !important;
	width: 109px !important;
	padding: 12px;
}

.dropdown-item--custom {
	padding: 0 !important;
	margin-bottom: 6px;
	font-size: 13px !important;

	&:last-child {
		margin-bottom: 0;
	}
}

.last-hide {
	&:last-child {
		display: none;
	}
}

.disabled-btn:disabled {
	background-color: #e9ecef !important;
	color: black !important;
	border: 1px solid #ced4da !important;
}

.menu-center {
	left: -22px !important;
	top: 35px !important;
}

.p-l-none {
	padding-left: 0;
}

.p-r-none {
	padding-right: 0;
}

.form-control.is-valid {
	padding-right: 12px;
}

.hoverable {
	color: $secondary-color;
	cursor: pointer;
}

.hoverable:hover {
	opacity: 0.5;
}

.modal-open .modal {
	overflow-y: scroll;
}

.form-control {
	background-color: #ffffff !important;
}

input::placeholder {
	padding-right: 1px;
}
input.has-success {
	border-color: #1ab394 !important;
	&:focus {
		border-color: #dc3545 !important;
	}
}

.has-success .form-control,
.has-success .form-control:focus {
	border-color: #1ab394 !important;
}

.border-collapse_separate {
	border-collapse: separate;
}

.cursor_default {
	cursor: default !important;
}

.cursor_pointer {
	cursor: pointer;
}

.hide-row {
	display: none;
}

.block-list {
	display: grid;
	padding-left: 15px;
	list-style-type: circle;
}

.simulated-link {
	text-decoration: underline;
	color: #0089ff;
	cursor: pointer;
	border: none;
	background: inherit;
	padding: 0;

	&-disabled {
		text-decoration: underline;
		color: #888;
	}
}

.tabs-container.catalogs {
	.panel-body {
		padding: 0;
	}

	.nav-tabs > li > a {
		padding: 0.3rem 0.7rem;
	}

	.table {
		margin-bottom: 0;

		th.id {
			width: 4.8rem;
		}

		th.actions {
			width: 5.8rem;
		}
	}
}

nav.pagination {
	display: flex;
	justify-content: center;
	margin-bottom: 1rem;

	li.active {
		a {
			color: #fff;
			background-color: #999;
			border-color: #999;
		}
	}
}

#exchange_rates-list.table {
	font-size: 0.7rem;
	display: block;
	overflow-x: scroll;

	th,
	td {
		padding: 0.3rem;
	}
}

input.form-control::placeholder,
input[type='date'].form-control.empty,
.form-control input::placeholder {
	font-style: italic;
	color: #c0c0c0 !important;
}
.width-115px {
	width: 115px;
}
.text-green {
	color: #28a745;
}
.badge-container {
	height: auto;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	white-space: nowrap;

	div {
		cursor: pointer;
		background-color: #ebedef;
		margin: 5px 5px 0 0;
		border-radius: 16px;
		height: 32px;
		line-height: 32px;
		padding: 0 15px 0 15px;
	}
}
tr.excluded {
	border-color: #aaaaaa;
	color: #a8a8a8;
}
tr {
	th.actions,
	td.actions {
		border-top: 0px solid #cecece !important;
		border-bottom: 0px solid #cecece !important;
		background-color: #f3f3f4 !important;
	}
}

.disabled {
	pointer-events: none;
	cursor: not-allowed;
	opacity: 0.6;
}
.btn-green {
	color: #fff;
	background-color: #28a745;
	border-color: #28a745;
}
.nowrap {
	white-space: nowrap;
}

.m-b-xxl {
	margin-bottom: 50px;
}

.m-t-none {
	margin-top: 0 !important;
}

label .field_with_errors {
	width: fit-content;
	float: inline-start;

	input[type='checkbox'] {
		box-shadow: 0px 0px 0px 1px #dc3545;
	}
}

.field_with_errors > input {
	border: 1px solid #dc3545;
}
