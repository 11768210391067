/** @format */

body {
	font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	background-color: $nav-bg;
	font-size: 13px;
	color: $text-color;
	overflow-x: hidden;
}

html,
body {
	height: 100%;
}

body.full-height-layout #wrapper,
body.full-height-layout #page-wrapper {
	height: 100%;
}

#page-wrapper {
	min-height: 100vh;
}

/*body.boxed-layout {
  background: $boxed-background;
}*/

body.boxed-layout #wrapper {
	background-color: $nav-bg;
	max-width: $boxed-width;
	margin: 0 auto;
	-webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
}

.top-navigation.boxed-layout #wrapper,
.boxed-layout #wrapper.top-navigation {
	max-width: 1300px !important;
}

.block {
	display: block;
}

.clear {
	display: block;
	overflow: hidden;
}

a {
	color: $basic-link-color;
	cursor: pointer;
}

a:hover,
a:focus {
	text-decoration: none;
}

.border-bottom {
	border-bottom: 1px solid $border-color !important;
}

.font-bold {
	font-weight: 600;
}

.font-normal {
	font-weight: 400;
}

.text-uppercase {
	text-transform: uppercase;
}

.font-italic {
	font-style: italic;
}

.b-r {
	border-right: 1px solid $border-color;
}

.hr-line-dashed {
	border-top: 1px dashed $border-color;
	color: #ffffff;
	background-color: #ffffff;
	height: 1px;
	margin: 20px 0;
}

.hr-line-solid {
	border-bottom: 1px solid $border-color;
	background-color: rgba(0, 0, 0, 0);
	border-style: solid !important;
	margin-top: 15px;
	margin-bottom: 15px;
}

video {
	width: 100% !important;
	height: auto !important;
}

/* GALLERY */
.gallery > .row > div {
	margin-bottom: 15px;
}

.fancybox img {
	margin-bottom: 5px;
	/* Only for demo */
	width: 24%;
}

/* Summernote text editor  */
.note-editor {
	height: auto !important;
	//min-height: 300px;
}

.note-editor.fullscreen {
	z-index: 2050;
}

.note-editor.note-frame.fullscreen {
	z-index: 2020;
}

.note-editor.note-frame .note-editing-area .note-editable {
	color: $text-color;
	padding: 15px;
}

.note-editor.note-frame {
	border: none;
}

.note-editor.panel {
	margin-bottom: 0;
}

/* MODAL */
.modal-content {
	background-clip: padding-box;
	background-color: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0);
	border-radius: 4px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	outline: 0 none;
	position: relative;
}

.modal-dialog {
	z-index: 2200;
}

.modal-body {
	padding: 20px 30px 30px 30px;
}

.inmodal .modal-body {
	background: #f8fafb;
}

.inmodal .modal-header {
	padding: 30px 15px;
	text-align: center;
	display: block;
}

.animated.modal.fade .modal-dialog {
	-webkit-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none;
}

.inmodal .modal-title {
	font-size: 26px;
}

.inmodal .modal-icon {
	font-size: 84px;
	color: #e2e3e3;
}

.modal-footer {
	margin-top: 0;
}

/* WRAPPERS */

#wrapper {
	width: 100%;
	overflow-x: hidden;
	display: -ms-flex;
	display: -webkit-flex;
	display: flex;
}

.wrapper {
	padding: 0 20px;
}

.wrapper-content {
	padding: 20px 10px 40px;
}

#page-wrapper {
	//padding: 0 15px;
	position: relative !important;
	flex-shrink: 1;
	width: calc(100% - 70px);
}

@media (min-width: 768px) {
	#page-wrapper {
		position: inherit;
	}
}

.title-action {
	text-align: right;
	padding-top: 30px;
}

.ibox-content h1,
.ibox-content h2,
.ibox-content h3,
.ibox-content h4,
.ibox-content h5,
.ibox-title h1,
.ibox-title h2,
.ibox-title h3,
.ibox-title h4,
.ibox-title h5 {
	margin-top: 5px;
}

ul.unstyled,
ol.unstyled {
	list-style: none outside none;
	margin-left: 0;
}

.big-icon {
	font-size: 160px !important;
	color: #e5e6e7;
}

/* FOOTER */

.footer {
	background: none repeat scroll 0 0 white;
	border-top: 1px solid $border-color;
	bottom: 0;
	left: 0;
	padding: 10px 20px;
	position: absolute;
	right: 0;
}

.footer.fixed_full {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	padding: 10px 20px;
	background: white;
	border-top: 1px solid $border-color;
}

.footer.fixed {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	padding: 10px 20px;
	background: white;
	border-top: 1px solid $border-color;
	margin-left: $sidebar-width;
}

body.mini-navbar .footer.fixed,
body.body-small.mini-navbar .footer.fixed {
	margin: 0 0 0 70px;
}

body.mini-navbar.fixed-sidebar .footer.fixed {
	margin: 0;
}

body.mini-navbar.canvas-menu .footer.fixed,
body.canvas-menu .footer.fixed {
	margin: 0 !important;
}

body.fixed-sidebar.body-small.mini-navbar .footer.fixed {
	margin: 0 0 0 $sidebar-width;
}

body.body-small .footer.fixed {
	margin-left: 0;
}

/* PANELS */

.panel-title > .small,
.panel-title > .small > a,
.panel-title > a,
.panel-title > small,
.panel-title > small > a {
	color: inherit;
}

.page-heading {
	border-top: 0;
	padding: 0 10px 20px 10px;
}

.panel-heading h1,
.panel-heading h2 {
	margin-bottom: 5px;
}

.panel-body {
	padding: 15px;
}

/* Bootstrap 3.3.x panels */
.panel {
	margin-bottom: 20px;
	background-color: #fff;
	border: 1px solid transparent;
	border-radius: 4px;
}

.panel-heading {
	color: white;
	padding: 10px 15px;
	border-bottom: 1px solid transparent;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.panel-footer {
	padding: 10px 15px;
	border-top: 1px solid $border-color;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}

.panel-default > .panel-heading {
	color: #333;
	background-color: #f5f5f5;
	border-color: $border-color;
}

.panel-default {
	border-color: $border-color;
}

.panel-group .panel + .panel {
	margin-top: 5px;
}

.panel-group .panel {
	margin-bottom: 0;
	border-radius: 4px;
}

/* TABLES */

.table > caption + thead > tr:first-child > td,
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > td,
.table > thead:first-child > tr:first-child > th {
	border-top: 0;
}

.table-bordered {
	border: 1px solid #ebebeb;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
	background-color: #f5f5f6;
	border-bottom-width: 1px;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
	border: 1px solid #e7e7e7;
}

.table > thead > tr > th {
	border-bottom: 1px solid #dddddd;
	vertical-align: bottom;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
	border-top: 1px solid $border-color;
	line-height: 1.42857;
	padding: 8px;
	vertical-align: top;
}

/* PANELS */

.panel.blank-panel {
	background: none;
	margin: 0;
}

.blank-panel .panel-heading {
	padding-bottom: 0;
}

.nav-tabs > li > a {
	color: #a7b1c2;
	font-weight: 600;
	padding: 10px 20px 10px 25px;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
	color: $text-color;
}

.ui-tab .tab-content {
	padding: 20px 0;
}

/* GLOBAL  */

.no-padding {
	padding: 0 !important;
}

.no-borders {
	border: none !important;
}

.no-margins {
	margin: 0 !important;
}

.no-top-border {
	border-top: 0 !important;
}

.ibox-content.text-box {
	padding-bottom: 0;
	padding-top: 15px;
}

.border-left-right {
	border-left: 1px solid $border-color;
	border-right: 1px solid $border-color;
}

.border-top-bottom {
	border-top: 1px solid $border-color;
	border-bottom: 1px solid $border-color;
}

.border-left {
	border-left: 1px solid $border-color;
}

.border-right {
	border-right: 1px solid $border-color;
}

.border-top {
	border-top: 1px solid $border-color;
}

.border-bottom {
	border-bottom: 1px solid $border-color;
}

.border-size-sm {
	border-width: 3px;
}

.border-size-md {
	border-width: 6px;
}

.border-size-lg {
	border-width: 9px;
}

.border-size-xl {
	border-width: 12px;
}

.full-width {
	width: 100% !important;
}

.link-block {
	font-size: 12px;
	padding: 10px;
}

.nav.navbar-top-links .link-block a {
	font-size: 12px;
}

.navbar-top-links {
	text-align: right;
}

.link-block a {
	font-size: 10px;
	color: inherit;
}

body.mini-navbar .branding {
	display: none;
}

img.circle-border {
	border: 6px solid #ffffff;
	border-radius: 50%;
}

.branding {
	float: left;
	color: #ffffff;
	font-size: 18px;
	font-weight: 600;
	padding: 17px 20px;
	text-align: center;
	background-color: $navy;
}

.login-panel {
	margin-top: 25%;
}

.icons-box h3 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.icons-box .infont a i {
	font-size: 25px;
	display: block;
	color: $text-color;
}

.fa-icon-large {
	font-size: 1.4em !important;
	line-height: 0.75em !important;
	vertical-align: -15%;
	margin: auto;
}

.icons-box .infont a {
	color: #a6a8a9;
}

.icons-box .infont a {
	padding: 10px;

	margin: 1px;
	display: block;
}

.ui-draggable .ibox-title {
	cursor: move;
}

.breadcrumb {
	background-color: #ffffff;
	padding: 0;
	margin-bottom: 0;
}

.breadcrumb > li a {
	color: inherit;
}

.breadcrumb > .active {
	color: inherit;
}

code {
	background-color: #f9f2f4;
	border-radius: 4px;
	color: #ca4440;
	font-size: 90%;
	padding: 2px 4px;
	white-space: nowrap;
}

.ibox {
	clear: both;
	margin-bottom: 25px;
	margin-top: 0;
	padding: 0;
}

.ibox.collapsed .ibox-content {
	display: none;
}

.ibox.collapsed .fa.fa-chevron-up:before {
	content: '\f078';
}

.ibox.collapsed .fa.fa-chevron-down:before {
	content: '\f077';
}

.ibox:after,
.ibox:before {
	display: table;
}

.ibox-title {
	background-color: $ibox-title-bg;
	border-color: $border-color;
	border-image: none;
	border-style: solid solid none;
	border-width: 1px;
	color: inherit;
	margin-bottom: 0;
	padding: 15px 90px 8px 15px;
	min-height: 48px;
	position: relative;
	clear: both;
	-webkit-border-radius: 3px 3px 0 0;
	-moz-border-radius: 3px 3px 0 0;
	border-radius: 2px 2px 0 0;
}

.ibox-content {
	background-color: $ibox-content-bg;
	color: inherit;
	padding: 15px 20px 20px 20px;
	border-color: $border-color;
	border-image: none;
	border-style: solid;
	border-width: 1px;
}

.ibox-footer {
	color: inherit;
	border-top: 1px solid $border-color;
	font-size: 90%;
	background: #ffffff;
	padding: 10px 15px;
}

table.table-mail tr td {
	padding: 12px;
}

.table-mail .check-mail {
	padding-left: 20px;
}

.table-mail .mail-date {
	padding-right: 20px;
}

.star-mail,
.check-mail {
	width: 40px;
}

.unread td a,
.unread td {
	font-weight: 600;
	color: inherit;
}

.read td a,
.read td {
	font-weight: normal;
	color: inherit;
}

.unread td {
	background-color: #f9f8f8;
}

.ibox-content {
	clear: both;
}

.ibox-heading {
	background-color: #f3f6fb;
	border-bottom: none;
}

.ibox-heading h3 {
	font-weight: 200;
	font-size: 24px;
}

.ibox-title h5 {
	display: inline-block;
	font-size: 14px;
	margin: 0 0 7px;
	padding: 0;
	text-overflow: ellipsis;
	float: none;
}

.ibox-title .label {
	margin-left: 4px;
}

.ibox-title .pull-right,
.ibox-title .float-right {
	position: absolute;
	right: 15px;
	top: 15px;
}

.ibox-tools {
	display: block;
	float: none;
	margin-top: 0;
	position: absolute;
	top: 15px;
	right: 15px;
	padding: 0;
	text-align: right;
}

.ibox-tools a {
	cursor: pointer;
	margin-left: 5px;
	color: #c4c4c4 !important;
}

.ibox-tools a.btn-primary {
	color: #fff !important;
}

.ibox-tools .dropdown-menu > li > a {
	padding: 4px 10px;
	font-size: 12px;
	color: $text-color !important;
}

.ibox .ibox-tools.open > .dropdown-menu {
	left: auto;
	right: 0;
}

.ibox-tools .dropdown-toggle::after {
	display: none;
}

.dropdown-item {
	width: auto;
}

.dropdown-item.active,
.dropdown-item:active {
	background-color: inherit;
	color: inherit;
}

/* BACKGROUNDS */

.gray-bg,
.bg-muted {
	background-color: $gray;
}

.white-bg {
	background-color: #ffffff;
}

.blue-bg,
.bg-success {
	background-color: $blue !important;
	color: #ffffff;
}

.navy-bg,
.bg-primary {
	background-color: $navy !important;
	color: #ffffff;
}

.lazur-bg,
.bg-info {
	background-color: $lazur !important;
	color: #ffffff;
}

.yellow-bg,
.bg-warning {
	background-color: $yellow !important;
	color: #ffffff;
}

.red-bg,
.bg-danger {
	background-color: $red !important;
	color: #ffffff;
}

.black-bg {
	background-color: #262626;
}

.panel-primary {
	border-color: $navy;
}

.panel-primary > .panel-heading {
	background-color: $navy;
	border-color: $navy;
}

.panel-success {
	border-color: $blue;
}

.panel-success > .panel-heading {
	background-color: $blue;
	border-color: $blue;
	color: #ffffff;
}

.panel-info {
	border-color: $lazur;
}

.panel-info > .panel-heading {
	background-color: $lazur;
	border-color: $lazur;
	color: #ffffff;
}

.panel-warning {
	border-color: $yellow;
}

.panel-warning > .panel-heading {
	background-color: $yellow;
	border-color: $yellow;
	color: #ffffff;
}

.panel-danger {
	border-color: $red;
}

.panel-danger > .panel-heading {
	background-color: $red;
	border-color: $red;
	color: #ffffff;
}

.progress-bar {
	background-color: $navy;
}

.progress-small,
.progress-small .progress-bar {
	height: 10px;
}

.progress-small,
.progress-mini {
	margin-top: 5px;
}

.progress-mini,
.progress-mini .progress-bar {
	height: 5px;
	margin-bottom: 0;
}

.progress-bar-navy-light {
	background-color: #3dc7ab;
}

.progress-bar-success {
	background-color: $blue;
}

.progress-bar-info {
	background-color: $lazur;
}

.progress-bar-warning {
	background-color: $yellow;
}

.progress-bar-danger {
	background-color: $red;
}

.panel-title {
	font-size: inherit;
}

.jumbotron {
	border-radius: 6px;
	padding: 40px;
}

.jumbotron h1 {
	margin-top: 0;
}

/* COLORS */

.text-navy {
	color: $navy !important;
}

.text-primary {
	color: inherit !important;
}

.text-success {
	color: $blue !important;
}

.text-info {
	color: $lazur !important;
}

.text-warning {
	color: $yellow !important;
}

.text-danger {
	color: $red !important;
}

.text-muted {
	color: #888888 !important;
}

.text-white {
	color: #ffffff;
}

.simple_tag {
	background-color: #f3f3f4;
	border: 1px solid #e7eaec;
	border-radius: 2px;
	color: inherit;
	font-size: 10px;
	margin-right: 5px;
	margin-top: 5px;
	padding: 5px 12px;
	display: inline-block;
}

.img-shadow {
	-webkit-box-shadow: 0 0 3px 0 rgba(145, 145, 145, 1);
	-moz-box-shadow: 0 0 3px 0 rgba(145, 145, 145, 1);
	box-shadow: 0 0 3px 0 rgba(145, 145, 145, 1);
}

.dashboards\.dashboard_2 nav.navbar,
.dashboards\.dashboard_3 nav.navbar,
.mailbox\.inbox nav.navbar,
.mailbox\.email_view nav.navbar,
.mailbox\.email_compose nav.navbar,
.dashboards\.dashboard_4_1 nav.navbar,
.metrics nav.navbar,
.metrics\.index nav.navbar,
.dashboards\.dashboard_5 nav.navbar {
	background: #fff;
}

a.close-canvas-menu {
	position: absolute;
	top: 10px;
	right: 15px;
	z-index: 1011;
	color: #a7b1c2;
}

a.close-canvas-menu:hover {
	color: #fff;
}

.close-canvas-menu {
	display: none;
}

.canvas-menu .close-canvas-menu {
	display: block;
}

.light-navbar .navbar.navbar-static-top {
	background-color: #fff;
}

/* FULL HEIGHT */

.full-height {
	height: 100%;
}

.fh-breadcrumb {
	height: calc(100% - 196px);
	margin: 0 -15px;
	position: relative;
}

.fh-no-breadcrumb {
	height: calc(100% - 99px);
	margin: 0 -15px;
	position: relative;
}

.fh-column {
	background: #fff;
	height: 100%;
	width: 240px;
	float: left;
}

.modal-backdrop {
	z-index: 2040 !important;
}

.modal {
	z-index: 2050 !important;
}

.spiner-example {
	height: 200px;
	padding-top: 70px;
}

legend {
	font-size: 1rem;
}

/* MARGINS & PADDINGS */

.p-xxs {
	padding: 5px;
}

.p-xs {
	padding: 10px;
}

.p-sm {
	padding: 15px;
}

.p-m {
	padding: 20px;
}

.p-md {
	padding: 25px;
}

.p-lg {
	padding: 30px;
}

.p-xl {
	padding: 40px;
}

.p-w-xs {
	padding: 0 10px;
}

.p-w-sm {
	padding: 0 15px;
}

.p-w-m {
	padding: 0 20px;
}

.p-w-md {
	padding: 0 25px;
}

.p-w-lg {
	padding: 0 30px;
}

.p-w-xl {
	padding: 0 40px;
}

.p-h-xs {
	padding: 10px 0;
}

.p-h-sm {
	padding: 15px 0;
}

.p-h-m {
	padding: 20px 0;
}

.p-h-md {
	padding: 25px 0;
}

.p-h-lg {
	padding: 30px 0;
}

.p-h-xl {
	padding: 40px 0;
}

.m-xxs {
	margin: 2px 4px;
}

.m {
	margin: 15px;
}

.m-xs {
	margin: 5px;
}

.m-sm {
	margin: 10px;
}

.m-md {
	margin: 20px;
}

.m-lg {
	margin: 30px;
}

.m-xl {
	margin: 50px;
}

.m-n {
	margin: 0 !important;
}

.m-l-none {
	margin-left: 0;
}

.m-l-xs {
	margin-left: 5px;
}

.m-l-sm {
	margin-left: 10px;
}

.m-l {
	margin-left: 15px;
}

.m-l-md {
	margin-left: 20px;
}

.m-l-lg {
	margin-left: 30px;
}

.m-l-xl {
	margin-left: 40px;
}

.m-l-n-xxs {
	margin-left: -1px;
}

.m-l-n-xs {
	margin-left: -5px;
}

.m-l-n-sm {
	margin-left: -10px;
}

.m-l-n {
	margin-left: -15px;
}

.m-l-n-md {
	margin-left: -20px;
}

.m-l-n-lg {
	margin-left: -30px;
}

.m-l-n-xl {
	margin-left: -40px;
}

.m-t-none {
	margin-top: 0;
}

.m-t-xxs {
	margin-top: 1px;
}

.m-t-xs {
	margin-top: 5px;
}

.m-t-sm {
	margin-top: 10px;
}

.m-t {
	margin-top: 15px;
}

.m-t-md {
	margin-top: 20px;
}

.m-t-lg {
	margin-top: 30px;
}

.m-t-xl {
	margin-top: 40px;
}

.m-t-n-xxs {
	margin-top: -1px;
}

.m-t-n-xs {
	margin-top: -5px;
}

.m-t-n-sm {
	margin-top: -10px;
}

.m-t-n {
	margin-top: -15px;
}

.m-t-n-md {
	margin-top: -20px;
}

.m-t-n-lg {
	margin-top: -30px;
}

.m-t-n-xl {
	margin-top: -40px;
}

.m-r-none {
	margin-right: 0;
}

.m-r-xxs {
	margin-right: 1px;
}

.m-r-xs {
	margin-right: 5px;
}

.m-r-sm {
	margin-right: 10px;
}

.m-r {
	margin-right: 15px;
}

.m-r-md {
	margin-right: 20px;
}

.m-r-lg {
	margin-right: 30px;
}

.m-r-xl {
	margin-right: 40px;
}

.m-r-n-xxs {
	margin-right: -1px;
}

.m-r-n-xs {
	margin-right: -5px;
}

.m-r-n-sm {
	margin-right: -10px;
}

.m-r-n {
	margin-right: -15px;
}

.m-r-n-md {
	margin-right: -20px;
}

.m-r-n-lg {
	margin-right: -30px;
}

.m-r-n-xl {
	margin-right: -40px;
}

.m-b-none {
	margin-bottom: 0;
}

.m-b-xxs {
	margin-bottom: 1px;
}

.m-b-xs {
	margin-bottom: 5px;
}

.m-b-sm {
	margin-bottom: 10px;
}

.m-b {
	margin-bottom: 15px;
}

.m-b-md {
	margin-bottom: 20px;
}

.m-b-lg {
	margin-bottom: 30px;
}

.m-b-xl {
	margin-bottom: 40px;
}

.m-b-n-xxs {
	margin-bottom: -1px;
}

.m-b-n-xs {
	margin-bottom: -5px;
}

.m-b-n-sm {
	margin-bottom: -10px;
}

.m-b-n {
	margin-bottom: -15px;
}

.m-b-n-md {
	margin-bottom: -20px;
}

.m-b-n-lg {
	margin-bottom: -30px;
}

.m-b-n-xl {
	margin-bottom: -40px;
}

.space-15 {
	margin: 15px 0;
}

.space-20 {
	margin: 20px 0;
}

.space-25 {
	margin: 25px 0;
}

.space-30 {
	margin: 30px 0;
}

// IMAGES SIZE

.img-sm {
	width: 32px;
	height: 32px;
}

.img-md {
	width: 64px;
	height: 64px;
}

.img-lg {
	width: 96px;
	height: 96px;
}

// BORDER RADIUS

.b-r-xs {
	-webkit-border-radius: 1px;
	-moz-border-radius: 1px;
	border-radius: 1px;
}

.b-r-sm {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

.b-r-md {
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
}

.b-r-lg {
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;
}

.b-r-xl {
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
}

// Fullscreen functions

.fullscreen-ibox-mode .animated {
	animation: none;
}

body.fullscreen-ibox-mode {
	overflow-y: hidden;
}

.ibox.fullscreen {
	z-index: 2030;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: auto;
	margin-bottom: 0;
}

.ibox.fullscreen .collapse-link {
	display: none;
}

.ibox.fullscreen .ibox-content {
	min-height: calc(100% - 48px);
}

// Bootstrap issue in 3.3.0 with modal https://github.com/twbs/bootstrap/issues/9855
// Fix
body.modal-open {
	padding-right: inherit !important;
}

// Fix modal in Safari browser
_::-webkit-full-page-media,
_:future,
:root body.modal-open .wrapper-content.animated {
	-webkit-animation: none;
	-ms-animation-nam: none;
	animation: none;
}

// Fix for modal backdrop with animate.css
body.modal-open .animated {
	animation-fill-mode: initial;
	z-index: inherit;
}

/* Show profile dropdown on fixed sidebar */
body.mini-navbar.fixed-sidebar .profile-element,
.block {
	display: block !important;
}

body.mini-navbar.fixed-sidebar .nav-header {
	padding: 33px 25px;
}

body.mini-navbar.fixed-sidebar .logo-element {
	display: none;
}

// Remove animation on fullscreen video

.fullscreen-video .animated {
	animation: none;
}

.list-inline > li {
	display: inline-block;
}

.custom-file-label {
	padding: 0.5rem 0.75rem;

	&::after {
		padding: 0.5rem 0.75rem;
	}
}
